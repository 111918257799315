import React, { Component } from 'react'
import Data from '../Json/profile.json'
import sushil from '../Assets/media/sushil.jpg'


export default class UserProfile extends Component {
    render() {
        return (
            <div className="container-fluid" id="team">
                <div className="row myrow">
                    <div className="col-sm-12">
                        <div className="userprofile-heading">
                            <p>Our team</p>
                        </div>
                        <div className="founder">
                            <div style={{ margin: 'auto' }} className="user-profile" >
                                <div className="userprofile-overlay">
                                    <div className="overlay-icon">
                                        <i className="fa fa-facebook"></i>
                                        <i className="fa fa-twitter"></i>
                                        <i className="fa fa-linkedin"></i>
                                    </div>
                                </div>
                                <img src={sushil} alt="Founder" />
                                <div className="userprofile-img-description">
                                    <p>Sushil Adhikari</p>
                                    <p>Founder</p>
                                </div>
                            </div>

                        </div>
                        <div className="userprofile-container">
                            {Data.map((data) => {
                                const { id, image, name, title } = data
                                return (< div key={id} className="user-profile" >
                                    <div className="userprofile-overlay">
                                        <div className="overlay-icon">
                                            <i className="fa fa-facebook"></i>
                                            <i className="fa fa-twitter"></i>
                                            <i className="fa fa-linkedin"></i>
                                        </div>
                                    </div>
                                    <img src={image} alt={name} />
                                    <div className="userprofile-img-description">
                                        <p>{name}</p>
                                        <p>{title}</p>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}