import React, { Component } from 'react'
import BackgroundText from './BackgroundText'
import Particle from './Particle'

export default class Background extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row" id="home">
                    <div className="col-sm-12 background">
                        <BackgroundText />
                        <Particle />
                    </div>
                </div>
            </div>
        )
    }
}