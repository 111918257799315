import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <div className="container-fluid" id='about'>
                <div className="row about-container" >
                    <div className="myrow">
                        <div className="col-sm-12 about-heding">
                            <p>Hey</p>
                            <p>we are akrehes academia
                            </p>
                            <hr style={{ backgroundColor: 'white', width: '60%', margin: 'auto', marginBottom: '20px' }} />
                            <p>Akrehes Academia is a platform to those people who have unique ideas, different thought, have some skills/talent, where they
                                can share their ideas, experiences and skill with each other. In other word, Akrehes Academia is a school where we ourselves are a
                                student, teacher and principles as well. It means when we have to learn from other then we learn like a student, similarly when it's
                                time to guide other then we guide like a teacher and lastly when we have to take decision, we think like a principal.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}