import React, { Component } from 'react';
import Particles from 'react-particles-js';

export default class Particle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            number: 40,
            size: 6,
        }
    }
    componentDidMount() {
        var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        if (width <= 992 && width > 480) {
            this.setState({ number: 30, size: 4 })
        }
        else if (width <= 480) {
            this.setState({ number: 20, size: 3 })
        }
        else {
            this.setState({ number: 40 })
        }
    }

    render() {
        return (
            <Particles
                params={{
                    "particles": {
                        "number": {
                            "value": this.state.number
                        },
                        "size": {
                            "value": this.state.size
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "repulse"
                            }
                        }
                    },
                    "repulse": {
                        "distance": 200,
                        "duration": 0.4
                    },
                }} >
            </Particles>
        )
    }
}