import React from 'react';
import Navbar from './component/Navbar'
import Background from './component/Background';
import About from './component/About';
import './Assets/css/App.css'
import UserProfile from './component/UserProfile';
import Developer from './component/Developer';
import Work from './component/Work';
import Connect from './component/Connect';

export default function App() {
  return (
    <div className="main-container">
      <Navbar />
      <Background />
      <About />
      <UserProfile />
      <Work />
      <Connect />
      <Developer />
    </div>
  );
}

