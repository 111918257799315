import React, { Component } from 'react';

export default class ContactusForm extends Component {
    render() {
        return (
            <section className="container-fluid">
                <p className="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
                    a matter of hours to help you.</p>
                <div className="row">
                    <div className="col-md-9 mb-md-0 mb-5">
                        <form className="contact-form" style={{ width: '80%', margin: 'auto' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="md-form mb-0">
                                        <input type="text" id="name" name="name" className="form-control" placeholder="Your Name" />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="md-form mb-0">
                                        <input type="text" id="email" name="email" className="form-control" placeholder="Your Email" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="md-form mb-0">
                                        <input type="text" id="subject" name="subject" className="form-control" placeholder="subject" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="md-form">
                                        <textarea type="text" id="message" name="message" rows="2" className="form-control md-textarea" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="text-center text-md-center" style={{ marginTop: '20px' }}>
                            <button className="btn btn-light" style={{ cursor: 'pointer' }}>Send Message</button>
                        </div>
                        <div className="status"></div>
                    </div>
                    <div className="col-md-3 text-center">
                        <ul className="list-unstyled mb-0">
                            <li><i className="fa fa-map-marker fa-2x"></i>
                                <p>Biratnagar, Nepal</p>
                            </li>

                            <li><i className="fa fa-phone mt-4 fa-2x"></i>
                                <p>+977 986-2317552</p>
                            </li>

                            <li><i className="fa fa-envelope mt-4 fa-2x"></i>
                                <p>rscience628@gmail.com</p>
                            </li>
                            <li className="contact-media-icon">
                                <a href="https://www.facebook.com/akrehesacademia/">
                                    <i className="fa fa-facebook"></i>
                                </a>
                                <a href="https://instagram.com/akrehes.academia?utm_medium=copy_link" style={{ color: 'black', textDecoration: 'none' }}>
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>

            </section >

        )
    }
}