import React, { Component } from 'react';
import logo from '../Assets/media/logo.png'

export default class Logo extends Component {
    render() {
        return (
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
        )
    }
}