import React, { Component } from 'react'

export default class BackgroundText extends Component {
    render() {
        return (
            <div className="backgroundtext-container">
                <p className="background-text">Akrehes Academia</p>
                <p className="background-text">Educational, interactive and research group</p>
                <button className="background-button"><a href="#contact">Connect with us</a></button>
            </div>
        )
    }
}