import React, { Component } from 'react'
import ContactusForm from './ContactusForm'

export default class Connect extends Component {
    render() {
        return (
            <div className="container-fluid" id='contact'>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="connect-heading">
                            <p>Contact Us
                            </p>
                        </div>
                    </div>
                </div>

                <div className="contact">
                    <ContactusForm />
                </div>
            </div>
        )
    }
}