import React, { Component } from 'react';

export default class Developer extends Component {
    render() {
        return (
            <div className="container-fluid developer">
                <div className="row">
                    <div className="col-sm-12">
                        <p>Developer: Ishan Rijal</p>
                    </div>
                </div>

            </div>


        )
    }
}