import React, { Component } from 'react';

export default class ProgressbarIndicator extends Component {
    componentDidMount() {
        window.onscroll = () => this.scrollProgress();
    }
    scrollProgress() {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById("myBar").style.width = scrolled + "%";
    }
    render() {
        return (
            <div className="progress-container">
                <div className="progress-bar" id="myBar"></div>
            </div>
        )
    }
}
