import React from 'react';
import Logo from './Logo'
import NavLinks from './NavLinks';
import ProgressbarIndicator from './ProgressbarIndicator';

export default function Navbar() {
    return (
        <div className="container-fluid fixed-top nav-container">
            <div className="row">
                <div className="col-sm-12">
                    <nav className="navbar navbar-expand-lg">
                        <Logo />
                        <NavLinks />
                    </nav>
                </div >
                <div className="col-sm-12">
                    <ProgressbarIndicator />
                </div>
            </div >
        </div>
    )
}