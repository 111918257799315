import React, { Component } from 'react'

export default class Work extends Component {
    render() {
        return (
            <div className="container-fluid" id='work'>
                <div className="row work-container">
                    <div className="work-myrow">
                        <div className="col-sm-12 work-heding">
                            <p>Why Akrehes Academia
                            </p>
                            <p>We organize contests to find young talents. We help to them to discover their talent. Due to current situation, it isn't possible to physically perform any actions. So, during this
                                pandemic, all our activities will held online. Mentors will write blogs on our facebook page, and we will soon start to make educational videos.

                                One major contribution that Akrehes academia hopes to provide is: to make difficult concepts easy for students. Our subject of study will be of both:
                                those included in our cirriculum by the ministry of education as well as those which are not included.

                                Our goal is completely education as well as skill oriented. Although we give importance to every field, we specially
                                focus on latest technology, latest discovery made on theoritical physics because these things are very poorly developed in our country.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}